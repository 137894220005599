import { type MaybeRefOrGetter, computed, toValue } from 'vue'

import type {
  SectionBannerApiProps,
  SectionBannerTheme,
} from '@backmarket/http-api/src/api-specs-content/models/section-banner'
import { tw } from '@backmarket/utils/string/tw'

import type {
  SectionBannerBackground,
  SectionBannerProps,
} from './SectionBanner.types'

const moodClassMap = {
  blush: tw`mood-blush`,
  bush: tw`mood-bush`,
  coffee: tw`mood-coffee`,
  cornflower: tw`mood-cornflower`,
  emerald: tw`mood-emerald`,
  purple: tw`mood-purple`,
  tangaroa: tw`mood-tangaroa`,
  violet: tw`mood-violet`,
  light: tw`mood-inverse`,
  dark: tw`mood-main`,
} as const satisfies Record<SectionBannerTheme | 'light' | 'dark', string>

export type MoodClass = (typeof moodClassMap)[keyof typeof moodClassMap]

const THEMES_WITH_DARK_TEXT: SectionBannerTheme[] = [
  'purple',
  'emerald',
  'blush',
  'cornflower',
]

function getTextClass(options: SectionBannerProps): string {
  if (!options.background) {
    return moodClassMap.dark
  }

  if ('image' in options.background) {
    return options.background.mode === 'light'
      ? moodClassMap.dark
      : moodClassMap.light
  }

  if ('theme' in options.background) {
    if (THEMES_WITH_DARK_TEXT.includes(options.background.theme)) {
      return moodClassMap.dark
    }

    return moodClassMap[options.background.theme]
  }

  return moodClassMap.dark
}

function getBackgroundClass(options: SectionBannerProps) {
  if (
    options?.background &&
    'theme' in options.background &&
    !!options.background.theme
  )
    return `bg-surface-default-hi ${moodClassMap[options.background.theme]}`

  return ''
}

function mapToProps(props: SectionBannerApiProps): SectionBannerProps {
  return {
    title: props.title,
    description: props.description,
    button: props.button,
    background:
      props.sectionBannerImageBackground ||
      props.sectionBannerMonochromeBackground,
  }
}

const hasBackground = (
  sectionBanner: SectionBannerProps,
): sectionBanner is SectionBannerProps & {
  background: SectionBannerBackground
} => 'background' in sectionBanner && !!sectionBanner.background

export function getPaddingClass(options: SectionBannerProps) {
  const horizontalPadding = 'px-24 md:px-72'
  if (!hasBackground(options)) {
    return `${horizontalPadding} py-32`
  }

  return 'image' in options.background
    ? `${horizontalPadding} py-32 md:py-72`
    : `${horizontalPadding} py-32`
}

function buildOverlay(options: SectionBannerProps) {
  if (options.background && 'image' in options.background) {
    const overlayClass =
      options.background.mode === 'light' ? 'mood-main' : 'mood-inverse'

    return { hasOverlay: true, overlayClass }
  }

  return { hasOverlay: false, overlayClass: '' }
}

export function getSectionBannerClasses(options: SectionBannerProps) {
  const backgroundClass = getBackgroundClass(options)
  const textClass = getTextClass(options)
  const paddingClass = getPaddingClass(options)

  const overlay = buildOverlay(options)

  return {
    backgroundClass,
    textClass,
    paddingClass,
    buttonClass: textClass,
    ...overlay,
  }
}

export function useSectionBanner(props: MaybeRefOrGetter<SectionBannerProps>) {
  return computed(() => getSectionBannerClasses(mapToProps(toValue(props))))
}
