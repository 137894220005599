<template>
  <div
    class="relative flex flex-col items-center justify-center gap-24 bg-cover bg-center"
    :class="[layout.backgroundClass, layout.paddingClass]"
    :style="{
      backgroundImage: backgroundImage,
    }"
  >
    <!-- overlay -->
    <div
      v-if="layout.hasOverlay"
      class="bg-surface-default-low absolute bottom-0 left-0 right-0 top-0 opacity-40"
      :class="layout.overlayClass"
    ></div>

    <div
      class="text-static-default-hi z-[2] flex flex-col items-center justify-center gap-8"
      :class="layout.textClass"
    >
      <h2 class="punchline text-center md:line-clamp-2">
        {{ title }}
      </h2>

      <div class="heading-2 text-center md:line-clamp-2">
        {{ description }}
      </div>
    </div>

    <CmsButton
      v-if="button"
      class="z-[2]"
      :class="layout.buttonClass"
      data-test="section-banner-cta-button"
      v-bind="button"
      full-width="adaptive"
      :tracking="tracking"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { SectionBannerApiProps } from '@backmarket/http-api/src/api-specs-content/models/section-banner'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

import CmsButton from '../Button/Button.vue'

import { useSectionBanner } from './useSectionBanner'

const props = defineProps<SectionBannerApiProps & ContentBlockProps>()
const layout = useSectionBanner(props)

const backgroundImage = computed(() => {
  if (
    props.sectionBannerImageBackground &&
    'image' in props.sectionBannerImageBackground
  ) {
    return `url(${props.sectionBannerImageBackground.image.src})`
  }

  return ''
})
</script>
