<template>
  <NuxtLayout :name="layout">
    <RevContainer>
      <div class="grid-standard mb-32 text-center">
        <div
          class="flex flex-col items-center md:col-span-4 lg:col-span-8 lg:col-start-3"
        >
          <RevIllustration
            alt=""
            class="my-32"
            :height="100"
            src="/img/auth/EmailSent.svg"
            :width="100"
          />
          <span class="heading-1 m-16 block">
            {{ i18n(translations.title) }}
          </span>
          <p class="text-action-default-low-hover body-1 m-0 inline-block">
            {{ i18n(translations.emailSent, { email }) }}
          </p>
          <FormattedMessage
            class="text-action-default-low-hover m-0 inline-block"
            :definition="translations.retry"
          >
            <template #link>
              <RevLink :to="to">
                {{ i18n(translations.retryLink) }}
              </RevLink>
            </template>
          </FormattedMessage>
        </div>
      </div>
    </RevContainer>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useUserStore } from '@backmarket/nuxt-module-oauth/useUserStore'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { ROUTES } from '~/scopes/auth/route-names'

import translations from './ResetPasswordConfirm.translations'

const i18n = useI18n()
const route = useRoute()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const userStore = useUserStore()

const email = computed(() => {
  return userStore.user.email || '*******'
})

const layout = computed(() => {
  return route.query.origin === 'auth' ? 'minimal' : 'default'
})

const to = computed(() => {
  return resolveLocalizedRoute({
    name: ROUTES.AUTH.RESET_PASSWORD,
    query: route.query,
  })
})
</script>
